import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import camion from "../assets/carousel/camion-offert-location-box-de-stockage.svg";
import access from "../assets/carousel/box-accessible-24h-24.svg";
import clean from "../assets/carousel/locaux-de-stockage-propres.svg";
import security from "../assets/carousel/securite-centre-stockage.svg";
import service from "../assets/carousel/service-client-montpellier.svg";

const Carrousel = () => {
  const options = {
    draggable: false,
    arrows: false,
    dots: true,
    slidesToShow: 3,
    centerMode: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    centerPadding: "10px",
    center: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const content = [
    {
      title: "Des contrats souples et sans engagement",
      text: "Chez nous, pas de frais de résiliation, vous partez quand vous voulez, avec un préavis de 15 jours seulement. Nous vous remboursons ensuite au prorata temporis. C’est aussi simple que ça. ",
      img: service,
    },
    {
      title: "7J/7",
      text: "Box accessible tout l'année (jours fériés et dimanche inclus)",
      img: access,
    },
    {
      title: "Chariots, transpalettes et monte-charges sur place",
      text: "Ne vous fatiguez pas, tout est fait pour transporter vos affaires facilement dans nos centres de stockage de Nîmes.",
      img: camion,
    },
    {
      title: "Stockez vos affaires en toute sécurité à Nîmes",
      text: "Nos box de stockage sont équipés d’alarmes individuelles. Les centres disposent de caméras de surveillance sous contrôle 24h/24 et de détection incendie.",
      img: security,
    },
    {
      title: "Trouvez vos fournitures de déménagement sur place !",
      text: "Nos centres de Nîmes vous proposent tout le matériel de qualité professionnelle pour protéger vos affaires et les entreposer dans votre box de stockage.",
      img: clean,
    },
  ];

  return (
    <Slider {...options}>
      {content.map((item, index) => (
        <div key={index}>
          <div
            style={{ height: "400px" }}
            className="w-64 mb-5 mx-auto bg-gray-50 border border-gray-100 shadow-lg rounded-2xl py-5 px-5"
          >
            <div className="mx-auto flex flex-col justify-between w-full h-full">
              <img className="m-auto h-32" src={item.img} alt={item.title} />
              <div className="m-auto align-middle text-center">
                <h4 className="w-full text-primary text-lg font-bold mt-2">
                  {item.title}
                </h4>
                <p className="my-auto h-full text-sm text-black">{item.text}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Carrousel;
