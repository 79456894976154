import { Layout } from "./components/layout";
import Formulaire from "./components/Formulaire";
import Carrousel from "./components/Carrousel";
import ScrollTop from "./components/common/ScrollTop";

import allees from "./assets/allees-box-stockage-nimes.65bd1627.png";
import gratuit from "./assets/comparateur-gratuit-box-stockage.png";
import devis from "./assets/devis-sans engagement-a-nimes.png";

import icon1 from "./assets/icon1.png";
import icon2 from "./assets/icon2.png";
import icon3 from "./assets/icon3.png";

import coworking from "./assets/Espace-coworking.aa258342.png";
import domiciliation from "./assets/domiciliation-commerciale.e828f03a.png";
import etageres from "./assets/etageres.c12485f2.png";
import reception from "./assets/reception-marchandises.a058017c.png";

import chariot from "./assets/chariot-cartons-stockage-dans-un-box.svg";

const App = () => {
  return (
    <Layout>
      <div className="w-full m-auto" style={{ maxWidth: "1200px" }}>
        <div className="xl:px-16 md:px-10 px-2">
          <div className="inline-flex justify-around flex-wrap w-full">
            <div
              id="formulaire"
              className="w-full px-4"
              style={{ maxWidth: "450px" }}
            >
              <h1 className="text-3xl text-center text-primary font-semibold tracking-wider">
                Trouver un box de stockage à Nîmes
              </h1>
              <p className="text-center">
                Indiquez vos critères pour recevoir les tarifs des{" "}
                <span className="font-bold">
                  <a className="" href="https://www.atout-box.fr">
                    box de stockage
                  </a>{" "}
                  à Nîmes{" "}
                </span>
                correspondant à vos critères
              </p>

              <Formulaire />

              <div className="flex justify-around my-4 w-full">
                <div className="flex justify-between">
                  <img
                    src={devis}
                    style={{ width: 47, height: 40 }}
                    alt="devis"
                  />
                  <p className="mx-4 text-primary text-xs my-auto">
                    Devis sans <br /> engagement
                  </p>
                </div>
                <div className="flex justify-between">
                  <img src={gratuit} width={50} alt="gratuit" />
                  <p className="mx-4 text-primary text-xs my-auto">
                    Comparateur <br /> 100% gratuit
                  </p>
                </div>
              </div>
            </div>

            <div className="my-auto">
              <img src={allees} width={450} height={500} alt="illustration" />
            </div>
          </div>
        </div>

        <div className="flex justify-center w-full mt-10">
          <div className="text-gray-500 text-center">
            <img src={icon1} className="mx-auto mb-1" width="80" alt="carton" />
            <div className="font-bold">4 000</div>
            <p>
              box de stockage <br /> à Nîmes
            </p>
          </div>
          <div className="mx-16 md:mx-24 lg:mx-40 text-gray-500 text-center">
            <img src={icon2} className="mx-auto mb-1" width="80" alt="carton" />
            <div className="font-bold">4 000</div>
            <p>
              box de stockage <br /> à Nîmes
            </p>
          </div>
          <div className="text-gray-500 text-center">
            <img src={icon3} className="mx-auto mb-1" width="80" alt="carton" />
            <div className="font-bold">4 000</div>
            <p>
              box de stockage <br /> à Nîmes
            </p>
          </div>
        </div>

        <div id="fonctionnement" className="mx-auto w-full">
          <div className="text-center mt-28 w-11/12 laptop:w-2/4 mx-auto">
            <h2 className="text-primary text-2xl laptop:text-3xl font-semibold">
              Nos options pour les professionnels
            </h2>
            <p className="mt-3 lg:mx-10">
              Complétez la location de votre{" "}
              <span className="font-bold">box de stockage</span> ou entrepôt par
              des <span className="font-bold">service complémentaires</span>.
              C'est sans engagement!{" "}
            </p>
          </div>
          <div className="w-full mx-auto flex flex-wrap justify-center">
            <div className="bg-white mx-10 my-5 shadow-2xl w-72 pt-4 pb-6 border px-5 rounded-2xl">
              <div className="w-full">
                <img
                  src={coworking}
                  className="mx-auto"
                  width={100}
                  alt="coworking"
                />
                <h3 className="font-bold text-xl text-center">
                  Espace de coworking
                </h3>
                <div
                  className="border-b mx-auto my-3 border-2 w-1/4"
                  style={{ borderColor: "rgb(85, 163, 254)" }}
                />
              </div>
              <div className="w-full text-gray-600 text-opacity-75">
                <p>
                  Profitez d'un espace entièrement dédié aux professionnels sur
                  votre lieu de stockage avec wifi et café.
                </p>
                <br />
                <p> Organisez vos réunions dans un bureau privé.</p>
              </div>
            </div>
            <div className="bg-white mx-10 my-5 shadow-2xl w-72 pt-4 pb-6 border px-5 rounded-2xl">
              <div className="w-full">
                <img
                  src={etageres}
                  className="mx-auto"
                  width={100}
                  alt="etageres"
                />
                <h3 className="font-bold text-xl text-center">
                  Achat ou location d'étagères
                </h3>
                <div
                  className="border-b mx-auto my-3 border-2 w-1/4"
                  style={{ borderColor: "rgb(118, 85, 254)" }}
                />
              </div>
              <div className="w-full text-gray-600 text-opacity-75">
                <p>
                  Nous montons et installons pour vos des étagères, directement
                  dans votre box de stockage.
                </p>
                <br />
                <p>
                  Il ne vous restera plus qu'à y ranger votre stock et vos
                  marchandises.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full mx-auto flex flex-wrap justify-center">
            <div className="bg-white mx-10 my-5 shadow-2xl w-72 pt-4 pb-6 border px-5 rounded-2xl">
              <div className="w-full">
                <img
                  src={domiciliation}
                  className="mx-auto"
                  width={100}
                  alt="domiciliation"
                />
                <h3 className="font-bold text-xl text-center">
                  Domiciliation commerciale
                </h3>
                <div
                  className="border-b mx-auto my-3 border-2 w-1/4"
                  style={{ borderColor: "rgb(171, 214, 110)" }}
                />
              </div>
              <div className="w-full text-gray-600 text-opacity-75">
                <p>
                  Enregistrez votre siège social sur votre lieu de stockage.
                  Votre boîte aux lettres sera mise à votre disposition sur
                  place.
                </p>
              </div>
            </div>
            <div className="bg-white mx-10 my-5 shadow-2xl w-72 pt-4 pb-6 border px-5 rounded-2xl">
              <div className="w-full">
                <img
                  src={reception}
                  className="mx-auto"
                  width={100}
                  alt="reception"
                />
                <h3 className="font-bold text-xl text-center">
                  Réception de marchandises{" "}
                  <span className="text-primary">offerte</span>
                </h3>
                <div
                  className="border-b mx-auto my-3 border-2 w-1/4"
                  style={{ borderColor: "rgb(253, 99, 14)" }}
                />
              </div>
              <div className="w-full text-gray-600 text-opacity-75">
                <p>Faites-vous livrer sur votre lieu de stockage.</p>
                <br />
                <p>
                  Nos équipes se chargeront de réceptionner vos colis et
                  palettes pour vous.
                </p>
              </div>
            </div>
          </div>
          <div className="text-center">
            <a
              href="#formulaire"
              className="px-8 py-1 mt-4 tracking-wider text-xl hover:shadow-2xl transition-shadow font-bold bg-primary text-white shadow-xl rounded-full my-2"
            >
              OBTENIR UN DEVIS
            </a>
          </div>
        </div>

        <div
          className="relative w-full bg-clip-padding bg-center md:bg-left bg-no-repeat pt-6 pb-6 mt-16"
          style={{
            backgroundImage: `url("${chariot}")`,
            minHeight: "650px",
          }}
        >
          <div className="laptop:absolute laptop:right-14 bg-white bg-opacity-90 shadow-lg rounded-3xl w-11/12 laptop:w-1/2 laptop:right-12 mx-auto px-8 py-2 my-10 laptop:min-w-950">
            <div className="my-4">
              <h3 className="text-primary font-semibold text-2xl text-center mt-2 mb-5">
                Vivre et travailler à Nîmes
              </h3>
              <p className="text-justify text-sm laptop:text-md leading-5 tracking-wide lg:mx-9" />
              <div className="mt-2">
                Nîmes compte près de 150 000 habitants. C’est la troisième ville
                la plus peuplée d’Occitanie après Toulouse et Montpellier.
              </div>
              <div className="mt-2">
                Située dans le département du Gard, elle dispose d’un patrimoine
                culturel remarquable. Son emplacement géographique, entre les
                Cévennes et la Méditerranée, fait d’elle une commune
                particulièrement agréable à vivre et ses habitants profitent de
                300 jours de soleil par an !
              </div>
              <div className="mt-2">
                En été, de nombreux touristes viennent visiter cette commune
                fondée à l’Antiquité, pour découvrir ses monuments tels que les
                arènes, la tour Magne ou encore la Maison Carrée.
              </div>
              <div className="mt-5">
                Grâce à son patrimoine culturel, la ville de Nîmes est classée
                ville d’art et d’histoire. Le textile a contribué à son
                rayonnement international par le passé, puisque Nîmes est connue
                pour sa production de soie et la toile de Nîmes (ancêtre du
                Denim).
              </div>
              <div className="mt-2">
                Au plan économique, la ville a retrouvé un certain dynamisme
                depuis 2000. Elle attire désormais de nombreuses entreprises, si
                bien que cette commune travaille maintenant à étendre ses zones
                d’activités économiques.
              </div>
              <p />
            </div>
            <div className="text-center mt-12 mb-5">
              <a
                className="bg-primary font-semibold shadow hover:shadow-xl rounded-2xl text-white px-8 py-3"
                href="#formulaire"
              >
                Recevoir les tarifs
              </a>
            </div>
          </div>
        </div>

        <div id="criteres" className="w-full mt-36">
          <div className="text-primary text-center">
            <h2 className="text-3xl font-semibold">
              Nos critères de sélection
            </h2>
            <p>
              Afin de vous proposer le meilleur service possible, nous avons des
              critères stricts de sélection.
            </p>
          </div>
          <div className="mt-10">
            <Carrousel />
          </div>
        </div>
      </div>
      <ScrollTop />
    </Layout>
  );
};

export default App;
