import logo from "../../assets/logo2.c3cd815e.png";

const Footer = () => {
  return (
    <footer className="w-full mt-28">
      <div className="bg-tertiary flex py-4 justify-around flex-wrap">
        <div className="h-full my-2 ">
          <img src={logo} width={150} alt="logo footer" />
        </div>
        <div className="my-auto">
          <p className="text-primary text-center font-semibold py-5">
            © {new Date().getFullYear()} Box de stockage Nîmes - Tous droits
            réservés.
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
